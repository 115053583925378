import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { DeleteModal, Loading } from '../../components';



function Roles() {

    const [loading, setLoding] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [roles, setRoles] = useState([])

    const [roleId, setRoleId] = useState(null)
    const [done, setDone] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)



    useEffect(() => {
        getRoles()
    }, [done])

    const getRoles = async () => {
        try {
            setErrorMessage('')
            setLoding(true)
            const response = await axiosInstance.get('/role/search');
            const responseData = response.data

            if (responseData) {
                setErrorMessage('')
                setRoles(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            setErrorMessage(error.response.data.message)
            toast.error(error.response.data.message)
            setLoding(false)
            console.error('Role creation failed:', error.response.data);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className=' w-full h-full p-5 '>
            {loading &&
                <Loading />
            }
            <div className=' w-full space-y-4 h-full  '>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Roles</h2>
                            <span className="px-3 py-1 text-xs text-orange-600 bg-orange-100 rounded-full dark:bg-neutral-800 dark:text-orange-400">{roles ? roles.length : 0} Role</span>
                        </div>
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">These companies have purchased in the last 12 months.</p>
                    </div>
                    <div className="flex items-center mt-4 gap-x-3">
                        <Link to='/Roles/new' className="flex items-center justify-center w-1/2 px-5 py-2 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>Add Role</span>
                        </Link>
                    </div>
                </div>
                <div className='w-full py-6 grid md:grid-cols-2 gap-3  grid-cols-1'>
                    {roles.length > 0 ?

                        roles.map(role => {
                            return (
                                <div key={role.id} className="relative flex  flex-col rounded-xl bg-white dark:bg-neutral-800  text-gray-700 shadow-md">
                                    <div className="p-6 text-start">
                                        <div className='flex mb-2 justify-between items-center'>
                                            <h5 className="block  text-xl font-semibold leading-snug tracking-normal text-orange-neutral-900 dark:text-gray-300 ">
                                                {role.name}
                                            </h5>
                                            <div className='flex justify-end items-center gap-2'>
                                                <a onClick={() => { setRoleId(role.id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                </a>
                                                <Link to={`/Roles/update/${role.id}`} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                </Link>
                                            </div>
                                        </div>
                                        <p className="block dark:text-gray-400  font-[300] leading-relaxed text-[14px] antialiased">
                                            {role.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })

                        :
                        (
                            <div className="min-h-60 md:col-span-2 flex flex-col bg-white border w-[100%] shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
                                <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                                    <svg className="size-10 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round">
                                        <line x1={22} x2={2} y1={12} y2={12} />
                                        <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                                        <line x1={6} x2="6.01" y1={16} y2={16} />
                                        <line x1={10} x2="10.01" y1={16} y2={16} />
                                    </svg>
                                    <p className="mt-2 text-sm text-gray-800 dark:text-gray-300">
                                        No data to show
                                    </p>
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>

            <DeleteModal setDeleteId={setRoleId} open={deleteModal} setOpen={setDeleteModal} DeleteId={roleId} Model='role' setDone={setDone} setLoding={setLoding} done={done} />

        </motion.div>
    )
}

export default Roles