import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAccessChecker } from '../../utils/Functions/Authorization';


const SideBareLink = ({ icon, path, title, open, subs }) => {
  const { checkAccess } = useAccessChecker();

  const [openDropDown, setOpenDropDown] = useState(false)
  const location = useLocation();

  return (
    <div className='w-full md:px-3'>
      {!subs ?
        <Link to={`${path}`} className={`flex ${open ? 'flex-row justify-start gap-3 py-3 px-5 rounded-xl' : 'flex-col justify-center rounded-md items-center gap-1 py-2 px-1'}   duration-500 transition-colors duration-300  items-center w-full  ${location.pathname === path ? '  text-orange-400 bg-orange-600/10  ' : ' hover:bg-orange-600/10  hover:text-orange-600 text-neutral-100 '} `}>
          {React.cloneElement(icon, {
            className: `${open ? 'w-5 h-5' : 'w-4 h-4'} transition-colors duration-100  
              `
          })}
          <p className={`capitalize ${open ? 'text-[14px]' : 'text-[11px]'}  font-[500] leading-[22px] `}>
            {title}
          </p>
        </Link>

        :

        !open ?

          <Link to={`${path}`} className={`flex ${open ? 'flex-row justify-start rounded-xl gap-3 py-3 px-3 ' : 'flex-col justify-center rounded-md items-center gap-1 py-2 px-1'}   duration-500 transition-colors duration-300  items-center w-full  ${location.pathname === path ? '  text-orange-400 bg-orange-600/10   ' : '   hover:bg-orange-600/10    hover:text-orange-600 text-neutral-100 '} `}>
            {React.cloneElement(icon, {
              className: ` ${open ? 'w-5 h-5' : 'w-4 h-4'} transition-colors duration-100 `
            })}
            <p className={`capitalize ${open ? 'text-[14px]' : 'text-[11px]'}  font-[500] leading-[22px] `}>
              {title}
            </p>
          </Link>
          :
          <div className='w-full grid-3 block '>
            <Link to={`${path}`} onClick={() => setOpenDropDown(!openDropDown)} className={`flex ${open ? 'flex-row rounded-xl justify-start' : 'flex-col rounded-md justify-center items-center'}   justify-between duration-500 transition-colors duration-300  gap-3 items-center w-full      ${location.pathname === path ? '  text-orange-400 bg-orange-600/10' : '   hover:bg-orange-600/10    hover:text-orange-600 text-neutral-100 '}  py-3 px-4 `}>
              <div className='flex items-center gap-3 '>
                {React.cloneElement(icon, {
                  className: `w-5 h-5 transition-colors duration-300 `
                })}
                <p className={`capitalize ${open ? 'text-[14px]' : 'text-[12px]'}  font-[500] leading-[22px] `}>
                  {title}
                </p>
              </div>

              <svg className={`size-4 ${openDropDown ? '' : 'rotate-180'} `} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="m18 15-6-6-6 6" /></svg>

            </Link>
            {openDropDown &&
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                className={`w-full overflow-hidden transition-[height] pl-6 duration-300 ${openDropDown ? 'max-h-screen' : 'max-h-0'}`}>
                <ul className="grid">
                  {subs.map((item, index) => {
                    if (checkAccess(item.accessSection, item.accessAction)) {
                      return (
                        <li key={index} className='mt-2'>
                          <Link
                            to={item.url}
                            className={`flex items-center w-full duration-500 text-[13px] font-[500] transition-colors duration-300 rounded-lg gap-3 items-center w-full ${location.pathname === item.url ? '  bg-orange-900  bg-orange-600 bg-orange-900 bg-opacity-10 text-orange-400  ' : '  hover:bg-opacity-5 hover:text-gray-100 text-gray-300 '} py-3 px-3`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-circle-dot"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <circle cx="12" cy="12" r="1" />
                            </svg>
                            {item.name}
                          </Link>
                        </li>
                      );
                    }
                    return null; // If access is denied, return null to skip rendering
                  })}
                </ul>
              </motion.div>
            }
          </div>


      }


    </div>
  )
}

export default SideBareLink
