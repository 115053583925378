import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Loading, Select } from '../../components';
import { DeleteModal } from '../../components';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';

import { motion, AnimatePresence } from 'framer-motion';

function Profiles() {
  const { t } = useTranslation();

  const { checkAccess } = useAccessChecker();

  const [ProfileId, setProfileId] = useState(null)
  const [searchValue, setSearchValue] = useState(null)

  const [loading, setLoding] = useState(false)
  const [Profile, setProfile] = useState([])
  const [done, setDone] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)


  const [Website, setWebsite] = useState([])

  const [filter, setFilter] = useState({ website_id: null })


  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });

  }





  useEffect(() => {
    getProfile()
    getWebsite()
  }, [ProfileId, done])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getProfile = async (page = 1) => {
    try {
      setLoding(true)
      const response = await axiosInstance.post(`/profiles/search?page=${page}&per_page=10`, filter);
      const responseData = response.data

      if (responseData) {
        setProfile(responseData.data)
        setCurrentPage(responseData.current_page);
        setTotalPages(responseData.last_page);

      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      getProfile(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      getProfile(currentPage - 1);
    }
  };

  const getWebsite = async () => {
    try {
      const response = await axiosInstance.get('/websites/search');
      const responseData = response.data

      if (responseData) {
        setWebsite(responseData.data)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };

  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/profiles/find`, { searchValue });
      const responseData = response.data

      if (responseData) {
        setProfile(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };




  const InitialseFilter = () => {
    return setFilter({ website_id: null })

  }


  const reset = () => {
    InitialseFilter()
    setSearchValue('')
    getProfile()
  }
  const filterBlogs = () => {
    getProfile()
  }


  return (
    <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className="flex flex-col w-full justify-start px-4 py-6 h-[100vh]">
      {loading &&
        <Loading />
      }
      {/* component */}
      <section className=" w-full mx-auto">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <div className="flex  items-center gap-x-3">
              <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Profiles</h2>
              <span className="px-3 py-1 text-xs text-orange-600 bg-orange-100 rounded-full dark:bg-neutral-800 dark:text-orange-400">{Profile.length} Profile</span>
            </div>
            <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">Here you can manage all the profiles of your websites.</p>
          </div>
          <div className="flex items-center mt-4 gap-x-3">

            {checkAccess('profiles', 'create') &&

              <Link to='/profiles/new' className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-orange-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Add Profile</span>
              </Link>
            }
          </div>
        </div>
        <div className="mt-6 md:flex md:items-center w-full md:justify-between">

          <form className="flex items-center md:w-1/3 w-full  ">
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute cursor-pointer z-50 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg onClick={(e) => reset(e)} className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                </svg>
              </div>
              <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} id="simple-search" className="peer h-11 block w-full border bg-neutral-50  border-neutral-200 rounded-lg text-sm  pl-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600" placeholder="Search branch name..." required />
            </div>
            <button type="submit" onClick={(e) => find(e)} disabled={!searchValue} className="h-[2.6rem] w-[2.6rem] flex justify-center items-center ms-2 text-sm font-medium text-white  rounded-lg text-white transition-colors duration-200 bg-orange-500 rounded-lg shrink-0  hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-orange-500">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>


          <div className='flex justify-end border rounded-lg mt-3 border-gray-200 dark:border-neutral-700 p-2 dark:bg-neutral-800 items-center gap-3 md:flex-row flex-col'>
            <Select label="Website" options={Website} width={11} name="website_id" handleChange={handleChange} defaultV={filter.website_id || null} />
            <div className='flex items-center gap-3 '>
              <button onClick={() => filterBlogs()} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 dark:text-black bg-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                </svg>


                <span>Filter</span>
              </button>
              <button onClick={(e) => reset(e)} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>


                <span>Reset</span>
              </button>

            </div>

          </div>

        </div>
        <div className="flex flex-col mt-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-neutral-200 dark:border-neutral-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                  <thead className="bg-neutral-50 dark:bg-neutral-800">
                    <tr>

                      <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Website
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Tag
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Region
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        City
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Title
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Slug
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                        Date
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">Actions</th>

                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-neutral-200 text-start dark:divide-neutral-700 dark:bg-neutral-900">
                    {
                      Profile.length > 0 &&
                      Profile.map(item => {
                        return (
                          <tr className='h-[4rem]'>
                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.website?.name}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.tag?.name}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.region?.name}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                              <div>
                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.city?.name || 'None'}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div>
                                <p className="text-neutral-500 dark:text-neutral-400">{item.title}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div>
                                <p className="text-neutral-500 dark:text-neutral-400">{item.slug}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div>
                                <p className="text-neutral-500 dark:text-neutral-400">{item.created_at}</p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className='flex justify-start items-center gap-2'>
                                {checkAccess('profiles', 'delete') &&

                                  <a onClick={() => { setProfileId(item.id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                  </a>
                                }
                                {checkAccess('profiles', 'update') &&

                                  <Link to={`/profiles/update/${item.id}`} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                  </Link>
                                }
                                {/* <Link to={`/products/${item.name}/${item.id}`} title={t("Transaction Details")} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-yellow-600 dark:bg-opacity-10 rounded-full bg-yellow-100 text-yellow-600'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" /><circle cx="12" cy="12" r="3" /></svg>
                                                                </Link> */}
                              </div>
                            </td>

                          </tr>
                        )
                      })
                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            Page <span className="font-medium text-neutral-700 dark:text-neutral-100">{currentPage} of {totalPages}</span>
          </div>
          <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>
              <span>Previous</span>
            </button>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
            >
              <span>Next</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </div>
        </div>

      </section>




      <DeleteModal setDeleteId={setProfileId} open={deleteModal} setOpen={setDeleteModal} DeleteId={ProfileId} Model='profiles' setDone={setDone} setLoding={setLoding} done={done} />

    </motion.div >


  )
}

export default Profiles