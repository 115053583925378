import React, { useEffect, useState } from 'react';
import { Link, useNavigate ,useParams } from 'react-router-dom';
import { Input, Select, Loading, Textarea, RichTextEditor } from '../../components'; // Import additional components
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

function FormTags() {
    const [done, setDone] = useState(false);
    const navigate = useNavigate();

    const [Website, setWebsite] = useState([])
    const [niches, setNiches] = useState(null)


    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
      name: null,
            slug: null,
            meta_title: null,
            meta_description: null,
            content: null,
            description: null,
            website_id: null,

    });

    const { id } = useParams();

    useEffect(() => {
        getWebsite()
        if (id) {
            getTagToUpdate();
        } else {
            initializeTag();
        }
    }, [done]);

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleContentChange = (content) => {
        setFormData({ ...formData, content });
    };

    const initializeTag = () => {
       return  setFormData({
            name: null,
            slug: null,
            meta_title: null,
            meta_description: null,
            content: null,
            description: null,
            website_id: null,


        });
    };

    const getWebsite = async () => {
        try {
            const response = await axiosInstance.get('/websites/search');
            const responseData = response.data

            if (responseData) {
                setWebsite(responseData.data)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    };


    const getTagToUpdate = async () => {
        try {
            setErrorMessage('');
            setLoading(true);
            const response = await axiosInstance.get(`/tags/get/${id}`);
            setFormData(response.data.data);
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setLoading(false);
            console.error('Failed to fetch tag:', error.response.data);
        }
    };

    const save = async (e) => {
        e.preventDefault();
        try {
            setErrorMessage('');
            setLoading(true);
            let response = null;
            if (id) {
                response = await axiosInstance.put(`/tags/update/${id}`, formData);
            } else {
                response = await axiosInstance.post('/tags/create', formData);
            }
            const responseData = response.data;
            if (responseData) {
                toast.success(responseData.message);
                if (!id) {
                    initializeTag();     
                                  navigate(-1)

                }
            }
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.response.data.message);
            toast.error(error.response.data.message);
            setLoading(false);
            console.error('Tag save failed:', error.response.data);
        }
    };





    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className='relative w-full h-full'>
            {loading && <Loading />}
            <div className='grid my-14 md:px-44 px-4'>
                <div className="sm:flex sm:items-center mb-6 sm:justify-between">
                    <div>
                        <ol className="flex items-center whitespace-nowrap mb-5">
                            <li className="inline-flex items-center">
                                <Link to='/tags' className="flex items-center text-sm text-neutral-500 hover:text-orange-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole">
                                        <circle cx="12" cy="16" r="1" />
                                        <rect x="3" y="10" width="18" height="12" rx="2" />
                                        <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                                    </svg>
                                    Tags
                                </Link>
                                <svg className="flex-shrink-0 mx-2 overflow-visible size-4 text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="m9 18 6-6-6-6"></path>
                                </svg>
                            </li>
                            <li className="inline-flex items-center text-sm font-semibold text-neutral-800 dark:text-neutral-100 truncate" aria-current="page">
                                {id ? 'Edit Tag' : 'New Tag'}
                            </li>
                        </ol>
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-xl font-semibold dark:text-neutral-100 text-neutral-800">Tag Form</h2>
                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500">Here you can add or edit a tag post.</p>
                    </div>
                </div>
                <div className='w-full flex md:flex-row gap-5 flex-col '>

                    <div className=' w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-2xl px-6 py-5'>
                        <form  method="POST">



                            <div className="grid gap-4 mb-6">

                                <div className="grid md:grid-cols-2 gap-4 ">
                                    <Select label="Website" options={Website} name="website_id" disabled={id ? true : false} handleChange={handleChange} defaultV={id ? formData.website?.id : formData.website_id || null} />
                                    <Input type='text' disabled={false} label="Name" name="name" handleChange={handleChange} defaultV={formData.name || null} />
                                    <Input type='text' disabled={false} label="Slug" name="slug" handleChange={handleChange} defaultV={formData.slug || null} />
                                    <Input type='text' disabled={false} label="Meta Title" name="meta_title" handleChange={handleChange} defaultV={formData.meta_title || null} />

                                </div>


                                <textarea
                                    placeholder='Meta Description...'
                                    rows={4}
                                    name="meta_description"
                                    onChange={handleChange}
                                    defaultValue={formData.meta_description || ''} // Use value instead of defaultValue for controlled textarea
                                    className='peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600'
                                ></textarea>

                                <textarea
                                    placeholder='Description...'
                                    rows={4}
                                    name="description"
                                    onChange={handleChange}
                                    defaultValue={formData.description || ''} // Use value instead of defaultValue for controlled textarea
                                    className='peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600'
                                ></textarea>

                                <RichTextEditor
                                    value={formData.content}
                                    onChange={handleContentChange}
                                />                            </div>
                            <div className='flex justify-end items-center gap-4 mt-24'>
                                <button onClick={(e) =>save(e)} type="submit" className='px-4 py-2 text-white bg-orange-600 dark:bg-orange-400 rounded-md'>Save</button>
                                <Link to='/tags' className='px-4 py-2 text-white bg-neutral-600 rounded-md'>Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default FormTags;
