import React, { useEffect, useState } from 'react';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { ReactComponent as UsersIcon } from '../../assets/navbar/ic_users.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/navbar/ic_transactions.svg';
import { ReactComponent as SuppliersIcon } from '../../assets/navbar/ic_supply.svg';
import { ReactComponent as ProductsIcon } from '../../assets/navbar/ic_products.svg';
import { ReactComponent as RestockIcon } from '../../assets/navbar/ic_restock.svg';
import { useTranslation } from 'react-i18next';
import ChartRestock from './ChartRestock';
import ChartTransactions from './ChartTransactions';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Loading, Select } from '../../components';
import { motion, AnimatePresence } from 'framer-motion';

function Dashboard() {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const [loading, setLoding] = useState()


  const [CardsStats, setCardsStats] = useState()
  const [GraphsStats, setGraphsStats] = useState()
  const [Website, setWebsite] = useState([])

  const [filter, setFilter] = useState({ website_id: null })

  const onChangeFilter = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    getWebsite()
    getCardsStats()
    getGraphstats()
  }, [])



  const getWebsite = async () => {
    try {
      const response = await axiosInstance.get('/websites/search');
      const responseData = response.data

      if (responseData) {
        setWebsite(responseData.data)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };



  const getCardsStats = async () => {
    try {
      setLoding(true)
      const response = await axiosInstance.post('/statistiques/CardsStats', filter);
      const responseData = response.data

      if (responseData) {
        setCardsStats(responseData)

      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };

  const getGraphstats = async () => {
    try {
      const response = await axiosInstance.post('/statistiques/GraphsStats', filter);
      const responseData = response.data

      if (responseData) {
        setGraphsStats(responseData)

      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };

  const InitialseFilter = () => {
    return setFilter({ website_id: null })

  }


  const reset = () => {
    InitialseFilter()
    getCardsStats()
    getGraphstats()
  }
  const filterStats = () => {
    getCardsStats()
    getGraphstats()
  }

  return (
    <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className="flex flex-col w-full justify-start md:p-7 p-3 md:gap-7 gap-3 h-[100vh]">
      {loading ?
        <Loading />
        :

        <>

          <div className='flex w-full md:flex-row flex-col md:justify-between items-center'>

            <div>
              <div className="flex  items-center gap-x-3">
                <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Dashboard</h2>
              </div>
              <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">Here you can track the statistiques of all your websites.</p>
            </div>


            <div className='flex justify-end border rounded-lg mt-3 border-gray-200 dark:border-neutral-700 p-2 dark:bg-neutral-800 items-center gap-3 md:flex-row flex-col'>
              <Select label="Website" options={Website} width={11} name="website_id" handleChange={onChangeFilter} defaultV={filter.website_id || null} />
              <div className='flex items-center gap-3 '>
                <button onClick={() => filterStats()} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 dark:text-black bg-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-neutral-100">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                  </svg>


                  <span>Filter</span>
                </button>
                <button onClick={(e) => reset(e)} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-orange-600 dark:hover:bg-orange-600 dark:bg-neutral-100">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>


                  <span>Reset</span>
                </button>

              </div>

            </div>

          </div>


          <div className='grid md:grid-cols-5 gap-3 w-full '>
            <div className="grid border border-gray-200 dark:border-neutral-700 shadow  border-dashed bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full  px-8 py-6">
              <div className='flex justify-between items-center'>
                <div className="flex flex-col justify-center items-start ">
                  <p className="">{t("Profiles")}</p>
                  <h4 className="text-2xl font-semibold   ">{CardsStats?.profiles.allTimeCount}</h4>

                </div>

                <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 border-[2px]  rounded-xl border-gray-200 dark:border-neutral-700 text-neutral-900 dark:text-neutral-100 ">
                  <UsersIcon className="w-5 h-5 transition-colors duration-300" />


                </div>
              </div>


              <div className='flex justify-start items-center gap-3 mt-3'>
                <p className='font-[500] text-[14px] text-neutral-500  '> {CardsStats?.profiles.difference} vs yesterday  </p>
                <div className={`flex justify-center gap-1 items-center border-[2.5px] font-[600] text-[10px] ${CardsStats?.profiles.difference >= 0 ? 'border-green-600 text-green-600' : "border-orange-400 text-orange-400"} py-0.5 px-1 rounded-full `}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class={`size-3 ${CardsStats?.regions.difference >= 0 ? '' : 'rotate-180' }`}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
                  </svg>
                  <p>
                    {CardsStats?.profiles.percentage} %

                  </p>

                </div>

              </div>
            </div>

            <div className="grid border border-gray-200 dark:border-neutral-700 shadow  border-dashed bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full  px-8 py-6">
              <div className='flex justify-between items-center'>
                <div className="flex flex-col justify-center items-start ">
                  <p className="">{t("Blogs")}</p>
                  <h4 className="text-2xl font-semibold   ">{CardsStats?.blogs.allTimeCount}</h4>

                </div>

                <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 border-[2px]  rounded-xl border-gray-200 dark:border-neutral-700 text-neutral-900 dark:text-neutral-100 ">
                  <SuppliersIcon className="w-5 h-5 transition-colors duration-300" />


                </div>
              </div>


              <div className='flex justify-start items-center gap-3 mt-3'>
                <p className='font-[500] text-[14px] text-neutral-500  '>  {CardsStats?.blogs.difference}  vs yesterday  </p>
                <div className={`flex justify-center gap-1 items-center border-[2.5px] font-[600] text-[10px] ${CardsStats?.blogs.difference >= 0 ? 'border-green-600 text-green-600' : "border-orange-400 text-orange-400"} py-0.5 px-1 rounded-full `}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class={`size-3 ${CardsStats?.regions.difference >= 0 ? '' : 'rotate-180' }`}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
                  </svg>
                  <p>
                    {CardsStats?.blogs.percentage} %

                  </p>

                </div>

              </div>
            </div>

            <div className="grid border border-gray-200 dark:border-neutral-700 shadow  border-dashed bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full  px-8 py-6">
              <div className='flex justify-between items-center'>
                <div className="flex flex-col justify-center items-start ">
                  <p className="">{t("Tags")}</p>
                  <h4 className="text-2xl font-semibold   ">{CardsStats?.tags.allTimeCount}</h4>

                </div>

                <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 border-[2px]  rounded-xl border-gray-200 dark:border-neutral-700 text-neutral-900 dark:text-neutral-100 ">
                  <ProductsIcon className="w-5 h-5 transition-colors duration-300" />


                </div>

              </div>

              <div className='flex justify-start items-center gap-3 mt-3'>
                <p className='font-[500] text-[14px] text-neutral-500  '>  {CardsStats?.tags.difference}  vs yesterday  </p>
                <div className={`flex justify-center gap-1 items-center border-[2.5px] font-[600] text-[10px] ${CardsStats?.tags.difference >= 0 ? 'border-green-600 text-green-600' : "border-orange-400 text-orange-400"} py-0.5 px-1 rounded-full `}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class={`size-3 ${CardsStats?.regions.difference >= 0 ? '' : 'rotate-180' }`}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
                  </svg>
                  <p>
                    {CardsStats?.tags.percentage} %

                  </p>

                </div>

              </div>
            </div>

            <div className="grid border border-gray-200 dark:border-neutral-700 shadow  border-dashed bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full  px-8 py-6">
              <div className='flex justify-between items-center'>
                <div className="flex flex-col justify-center items-start ">
                  <p className="">{t("Regions")}</p>
                  <h4 className="text-2xl font-semibold   ">{CardsStats?.regions.allTimeCount} </h4>

                </div>

                <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 border-[2px]  rounded-xl border-gray-200 dark:border-neutral-700 text-neutral-900 dark:text-neutral-100 ">
                  <TransactionsIcon className="w-5 h-5 transition-colors duration-300" />


                </div>
              </div>

              <div className='flex justify-start items-center gap-3 mt-3'>
                <p className='font-[500] text-[14px] text-neutral-500  '>  {CardsStats?.regions.difference}  vs yesterday  </p>
                <div className={`flex justify-center gap-1 items-center border-[2.5px] font-[600] text-[10px] ${CardsStats?.regions.difference >= 0 ? 'border-green-600 text-green-600' : "border-orange-400 text-orange-400"} py-0.5 px-1 rounded-full `}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class={`size-3 ${CardsStats?.regions.difference >= 0 ? '' : 'rotate-180' }`}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
                  </svg>
                  <p>
                    {CardsStats?.regions.percentage} %

                  </p>

                </div>

              </div>
            </div>

            <div className="grid border border-gray-200 dark:border-neutral-700 shadow  border-dashed bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full  px-8 py-6">
              <div className='flex justify-between items-center'>
                <div className="flex flex-col justify-center items-start ">
                  <p className="">{t("Cities")}</p>
                  <h4 className="text-2xl font-semibold   ">{CardsStats?.cities.allTimeCount}  </h4>

                </div>

                <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 border-[2px]  rounded-xl border-gray-200 dark:border-neutral-700 text-neutral-900 dark:text-neutral-100 ">
                  <RestockIcon className="w-5 h-5 transition-colors duration-300" />


                </div>
              </div>

              <div className='flex justify-start items-center gap-3 mt-3'>
                <p className='font-[500] text-[14px] text-neutral-500  '>  {CardsStats?.cities.difference}  vs yesterday  </p>
                <div className={`flex justify-center gap-1 items-center border-[2.5px] font-[600] text-[10px] ${CardsStats?.cities.difference >= 0 ? 'border-orange-600 text-orange-600' : "border-orange-400 text-orange-400"} py-0.5 px-1 rounded-full `}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class={`size-3 ${CardsStats?.regions.difference >= 0 ? '' : 'rotate-180' }`}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18" />
                  </svg>
                  <p>
                    {CardsStats?.cities.percentage} %

                  </p>

                </div>

              </div>

            </div>

          </div>





          <div className='flex justify-center md:flex-row flex-col item-center w-full  md:gap-7 gap-3'>
            <div className='flex justify-center items-center md:w-1/2 w-full'>
              <ChartRestock data={GraphsStats?.monthlyProfileCounts} title='Profiles count per month' label='Total' />
            </div>
            <div className='flex justify-center items-center md:w-1/2 w-full'>
              <ChartTransactions data={GraphsStats?.monthlyBlogCounts} title='Blogs count per month' label='Total' />

            </div>

          </div>

        </>
      }


    </motion.div>
  )
}

export default Dashboard;
