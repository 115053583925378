import { Routes, Route } from 'react-router-dom';
import { Login, Dashboard, Users, Roles, FormRoles, Blogs, Tags,Leads , Regions, Cities, FormTags, FormRegions, FormCities, FormBlogs, FormProfiles, Profiles, NotFoundPage, FormUsers, Settings, Websites } from '../pages';
import { MainLayout } from '../layout';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';



const Routers = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*Start******************************************* */}
        <Route
          path="/Roles"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Roles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*End******************************************* */}

        {/* **************************************Users*Start******************************************* */}

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Users />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/users/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Users*End******************************************* */}


        {/* **************************************Profiles*Start******************************************* */}

        <Route
          path="/profiles"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Profiles />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/profiles/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormProfiles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profiles/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormProfiles />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Profiles*End******************************************* */}


        {/* **************************************Blogs*Start******************************************* */}

        <Route
          path="/blogs"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Blogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/blogs/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormBlogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormBlogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Blogs*End******************************************* */}




        {/* **************************************Tags*Start******************************************* */}

        <Route
          path="/Tags"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Tags />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/Tags/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormTags />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Tags/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormTags />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Tags*End******************************************* */}




        {/* **************************************Regions*Start******************************************* */}

        <Route
          path="/Regions"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Regions />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/Regions/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRegions />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Regions/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRegions />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Regions*End******************************************* */}




        {/* **************************************Cities*Start******************************************* */}

        <Route
          path="/Cities"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Cities />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/Cities/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormCities />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Cities/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormCities />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Cities*End******************************************* */}

        <Route
          path="/Websites"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Websites />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Leads"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Leads />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
