import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SideBareLink } from '../../components'
import { ReactComponent as DashboardIcon } from '../../assets/navbar/ic_analytics.svg';
import { ReactComponent as SettingsIcon } from '../../assets/navbar/ic_settings.svg';
import { ReactComponent as UsersIcon } from '../../assets/navbar/ic_users.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/navbar/ic_transactions.svg';
import { ReactComponent as SuppliersIcon } from '../../assets/navbar/ic_supply.svg';
import { ReactComponent as ProductsIcon } from '../../assets/navbar/ic_products.svg';
import { ReactComponent as RolesIcon } from '../../assets/navbar/ic_roles.svg';
import { ReactComponent as RestockIcon } from '../../assets/navbar/ic_restock.svg';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { motion, AnimatePresence } from 'framer-motion';


const SideBar = ({ open, setOpen }) => {


  const { currentUser, fetchUser } = useCurrentUser();
  const [popover, setPopover] = useState(false)
  const { checkAccess } = useAccessChecker();

  useEffect(() => {
    fetchUser()
  }, [])

  const [websitesubs, setWebsiteSubs] = useState([
    { url: '/Tags', name: 'Tags', accessSection: 'tags', accessAction: 'read' },
    { url: '/Regions', name: 'Regions', accessSection: 'regions', accessAction: 'read' },
    { url: '/Cities', name: 'Cities', accessSection: 'cities', accessAction: 'read' },

  ]);


  return (
    <>
      <aside className={`md:flex  z-50 fixed flex-col gap-5 bg-neutral-800 ${open ? 'md:w-[15%] w-64  px-2  h-[100vh]' : 'md:w-[5.5rem] hidden z-20 min-h-screen'}  transition-all duration-300 shadow border-neutral-200 dark:border-neutral-700  border-r border-dashed`}>
        <div className={`relative z-50 flex justify-start items-center w-full gap-4 w-[85%]  mx-auto border-orange-500/20 ${open ? 'border-b ' : ''}  justify-center  items-center py-8`}>

          <a onMouseEnter={() => setPopover(true)}
            onMouseLeave={() => setPopover(false)}
            className='p-2 cursor-pointer rounded-lg bg-orange-600 '>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-neutral-800">
              <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
            </svg>


          </a>

          {popover &&
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className={` flex flex-col  ${!open ? '' : 'hidden'}  items-start justify-center border border-neutral-200 bg-white z-50 dark:border-neutral-700 dark:bg-neutral-800 px-5 py-3 rounded-xl absolute top-5 left-[6rem] w-fit`}>
              <h1 className='text-[15px] text-[700] text-neutral-900 dark:text-neutral-200'>{currentUser?.fullName}</h1>
              <p className='text-[13px] text-[400] text-neutral-400'>{currentUser?.email}</p>
            </motion.div>
          }

          <div className={` ${open ? '' : 'hidden'} flex flex-col items-start justify-center `}>
            <h1 className='text-[16px] text-[600] text-neutral-200'>{currentUser?.fullName}</h1>
            <p className='text-[13px] text-[400] text-neutral-400'>{currentUser?.email}</p>
          </div>

        </div>

        <div className={`flex flex-col justify-center items-start  z-50 md:mt-1 mt-4 place-items-center w-full gap-3  `}>


          {checkAccess('users', 'read') &&
            <SideBareLink path='/' title="dashboard" open={open} icon={<DashboardIcon />} />

          }


          {checkAccess('websites', 'read') &&
            <SideBareLink path='/Websites' title="WebSites" subs={websitesubs} open={open} icon={<ProductsIcon />} />

          }
          {checkAccess('profiles', 'read') &&
            <SideBareLink path='/profiles' title="Profiles" open={open} icon={<TransactionsIcon />} />

          }
          {checkAccess('blogs', 'read') &&
            <SideBareLink path='/blogs' title="Blogs" open={open} icon={<SuppliersIcon />} />

          }
          {checkAccess('leads', 'read') &&
            <SideBareLink path='/Leads' title="Leads" open={open} icon={<UsersIcon />} />
          }
          {checkAccess('users', 'read') &&
            <SideBareLink path='/users' title="users" open={open} icon={<UsersIcon />} />
          }

          {checkAccess('roles', 'read') &&
            <SideBareLink path='/Roles' title="Roles" open={open} icon={<RolesIcon />} />
          }

          {checkAccess('settings', 'read') &&
            <SideBareLink path='/settings' title="settings" open={open} icon={<SettingsIcon />} />
          }


        </div>











      </aside >

      {open &&
        <div onClick={() => setOpen(false)} className='absolute top-0  w-screen h-screen bg-black/10  dark:bg-black/30 z-40 md:hidden'></div>

      }
    </>
  )
}

export default SideBar
