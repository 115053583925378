import React from 'react'

function Loading() {
    return (
        <div >
            <div className="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-50 flex justify-center items-center dark:bg-neutral-800 rounded-xl shadow border border-gray-200 dark:border-neutral-700 w-36 h-36">
                <l-trefoil
                    size="40"
                    stroke="4"
                    stroke-length="0.15"
                    bg-opacity="0.1"
                    speed="1.4"
                    color="orange"
                ></l-trefoil>
            </div>
        </div>
    )
}

export default Loading