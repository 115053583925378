import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Input, SelectVariant, Loading, } from '../../components'; // Import additional components
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Select from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';


function FormProfiles() {
  const navigate = useNavigate();

  const [done, setDone] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [TagsList, setTagsList] = useState([]);
  const [CitiesList, setCitiesList] = useState([]);
  const [regions, setRegions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    age: '',
    snapchat: '',
    whatsapp: '',
    street: '',
    type_of_woman: [],
    images: [],
    status: '',
    website_id: '',
    city_id: '',
    region_id: '',
    region_id: '',
    slug: null,
    schedule_at: null

  });

  useEffect(() => {
    if (formData.website_id) {
      getTags()
      getRegions()
    }
  }, [formData.website_id])

  useEffect(() => {
    if (formData.website_id && formData.region_id) {
      getCities()
    }
  }, [formData.region_id])

  const { id } = useParams();

  const statusOptions = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
    { value: 'separated', label: 'Separated' },
    { value: 'in_a_relationship', label: 'In a Relationship' },
    { value: 'engaged', label: 'Engaged' },
    { value: 'complicated', label: 'It\'s Complicated' },
    { value: 'open_relationship', label: 'Open Relationship' },
    { value: 'polyamorous', label: 'Polyamorous' }
  ];

  const optionsWomen = [
    { id: 'blonde', name: 'Blonde' },
    { id: 'black', name: 'Black' },
    { id: 'mixed', name: 'Mixed' },
    { id: 'curvy', name: 'Curvy' },
    { id: 'athletic', name: 'Athletic' },
    { id: 'petite', name: 'Petite' },
    { id: 'plus_size', name: 'Plus Size' },
    { id: 'average', name: 'Average' },
    { id: 'slim', name: 'Slim' },
    { id: 'muscular', name: 'Muscular' },
    { id: 'tall', name: 'Tall' },
    { id: 'short', name: 'Short' },
    { id: 'natural', name: 'Natural' },
    { id: 'voluptuous', name: 'Voluptuous' },
    { id: 'elegant', name: 'Elegant' },
    { id: 'sophisticated', name: 'Sophisticated' },
    { id: 'youthful', name: 'Youthful' },
  ];

  const formattedOptions = (options) => {
    return options.map(option => ({
      value: option.id,
      label: option.name
    }));
  }


  useEffect(() => {
    getWebsites();
    if (id) {
      getProfileToUpdate();
    } else {
      initializeProfile();
    }
  }, [done, id]);

  const handleChange = (e) => {
    const { type, name, value, checked, files, selectedOptions } = e.target || {};

    if (Array.isArray(value)) {
      const selectedValues = value.map(option => option.value);
      setFormData(prevFormData => ({ ...prevFormData, [name]: selectedValues }));
    } else if (type === 'file') {
      if (files) {
        const newFiles = Array.from(files);

        // Check for file size limit
        if (newFiles.some(file => file.size > 3 * 1024 * 1024)) {
          toast.warning('File size exceeds 3 MB limit');
          e.target.value = ''; // Clear the file input
          return;
        }

        // Check the current number of images
        const totalImages = formData.images.length + newFiles.length;
        if (totalImages > 3) {
          toast.warning('You can only upload up to 3 images');
          e.target.value = ''; // Clear the file input
          return;
        }

        // Convert files to base64 and store them
        const fileReaders = newFiles.map(file => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          return new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject; // Handle file reading errors
          });
        });

        Promise.all(fileReaders)
          .then(results => {
            setFormData(prevFormData => ({
              ...prevFormData,
              images: [...prevFormData.images, ...results]
            }));
          })
          .catch(error => {
            toast.error('Error reading files');
          });
      }
    } else if (type === 'checkbox') {
      // Handle checkbox input
      setFormData(prevFormData => ({ ...prevFormData, [name]: checked }));
    } else {
      // Handle other inputs
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    }
  };


  const initializeProfile = () => {
    setFormData({
      title: '',
      age: '',
      snapchat: '',
      whatsapp: '',
      street: '',
      type_of_woman: [],
      images: [],
      status: '',
      website_id: '',
      city_id: '',
      region_id: '',
      slug: null,
      schedule_at: null
    });
  };

  const getWebsites = async () => {
    try {
      const response = await axiosInstance.get('/websites/search');
      setWebsites(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const getTags = async () => {
    try {
      const response = await axiosInstance.post('/tags/search', { website_id: formData.website_id });
      setTagsList(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getRegions = async () => {
    try {
      const response = await axiosInstance.post('/regions/search', { website_id: formData.website_id });
      setRegions(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getCities = async () => {
    try {
      const response = await axiosInstance.post('/cities/search', { website_id: formData.website_id, region_id: formData.region_id });
      setCitiesList(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getProfileToUpdate = async () => {
    try {
      setErrorMessage('');
      setLoading(true);
      const response = await axiosInstance.get(`/profiles/get/${id}`);
      setFormData(response.data.data);
      setLoading(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setLoading(false);
      console.error('Failed to fetch profile:', error.response.data);
    }
  };

  const save = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage('');
      setLoading(true);
      let response = null;
      if (id) {
        response = await axiosInstance.put(`/profiles/update/${id}`, formData);
      } else {
        response = await axiosInstance.post('/profiles/create', formData);
      }
      const responseData = response.data;
      if (responseData) {
        toast.success(responseData.message);
        if (!id) {
          initializeProfile();
          navigate(-1)

        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      toast.error(error.response.data.message);
      setLoading(false);
      console.error('Profile save failed:', error.response.data);
    }
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0.5rem',
      borderColor: state.isFocused ? '#3B82F6' : '#E5E7EB', // Tailwind's blue-500 and neutral-200
      boxShadow: state.isFocused ? '0 0 0 1px #3B82F6' : '', // Focus ring
      '&:hover': {
        borderColor: '#3B82F6' // Change border color on hover
      },
      borderRadius: '0.375rem', // Tailwind's rounded-lg
      backgroundColor: '#F9FAFB', // Tailwind's neutral-50
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F9FAFB', // Tailwind's neutral-50
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3B82F6' : '#F9FAFB', // Highlight selected with blue-500
      '&:hover': {
        backgroundColor: '#93C5FD' // Lighter blue on hover (blue-300)
      },
      color: state.isSelected ? '#FFFFFF' : '#111827', // White text for selected, otherwise neutral-900
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#E5E7EB', // Tailwind's neutral-200 for selected options
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#111827', // Tailwind's neutral-900 for the text of selected options
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#6B7280', // Tailwind's neutral-400 for remove icon
      '&:hover': {
        backgroundColor: '#3B82F6', // Tailwind's blue-500 on hover for remove button
        color: '#FFFFFF' // White icon when hovered
      },
    })
  };

  const deleteImage = (index) => {
    setFormData(prevFormData => {
      const updatedImages = prevFormData.images.filter((_, i) => i !== index);
      return { ...prevFormData, images: updatedImages };
    });
  };


  return (
    <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className='relative w-full h-full'>
      {loading && <Loading />}
      <div className='grid my-14 md:px-44 px-4'>
        <div className="sm:flex sm:items-center mb-6 sm:justify-between">
          <div>
            <ol className="flex items-center whitespace-nowrap mb-5">
              <li className="inline-flex items-center">
                <Link to='/profiles' className="flex items-center text-sm text-neutral-500 hover:text-orange-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole">
                    <circle cx="12" cy="16" r="1" />
                    <rect x="3" y="10" width="18" height="12" rx="2" />
                    <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                  </svg>
                  Profiles
                </Link>
                <svg className="flex-shrink-0 mx-2 overflow-visible size-4 text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m9 18 6-6-6-6"></path>
                </svg>
              </li>
              <li className="inline-flex items-center text-sm font-semibold text-neutral-800 dark:text-neutral-100 truncate" aria-current="page">
                {id ? 'Edit Profile' : 'New Profile'}
              </li>
            </ol>
            <div className="flex items-center gap-x-3">
              <h2 className="text-xl font-semibold dark:text-neutral-100 text-neutral-800">Profile Form</h2>
            </div>
            <p className="mt-1 text-start text-sm text-neutral-500">Here you can add or edit a profile post.</p>
          </div>
        </div>
        <div className='w-full flex md:flex-row gap-5 flex-col '>
          <div className='w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-2xl px-6 py-5'>
            <form onSubmit={save} method="POST">
              {formData?.images?.length < 3 && (
                <div className="flex items-center justify-center mb-5 border-2 w-full h-[25rem] mx-auto rounded-xl p-2 border-neutral-200 border-dashed dark:border-neutral-700">
                  <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full rounded-xl cursor-pointer bg-neutral-50 dark:bg-neutral-900 hover:bg-neutral-100">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-camera w-8 h-8 mb-4 text-neutral-500 dark:text-neutral-400">
                        <path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" />
                        <circle cx={12} cy={13} r={3} />
                      </svg>
                      <p className="mb-2 text-xs text-neutral-500 dark:text-neutral-400 font-semibold">Upload photo</p>
                      <div className='text-center text-xs text-neutral-500 pb-4 dark:text-neutral-400 font-medium'>
                        Allowed *.jpeg, *.jpg, *.png, *.gif <br />
                        <span>Max size of 3 MB</span>
                      </div>
                    </div>
                    <input
                      onChange={handleChange}
                      id="dropzone-file"
                      type="file"
                      name="images"
                      multiple
                      accept="image/*"
                      className="hidden"
                    />
                  </label>
                </div>
              )}

              {formData?.images?.length > 0 && (
                <>
                  <div className="flex gap-2  items-center justify-center pt-5 pb-6 w-full">
                    {formData?.images?.map((img, index) => (
                      <div className='relative'>
                        <img
                          key={index}
                          src={img}
                          alt={`Uploaded ${index}`}
                          className='rounded-xl relative m-1 object-cover h-[5rem] w-[5rem]'
                        />
                        <a
                          onClick={() => deleteImage(index)}
                          className='flex absolute top-2 right-2 z-50 cursor-pointer  justify-center items-center gap-2 p-2 rounded-full border text-[12px] border-neutral-200 bg-neutral-50  dark:bg-neutral-900 dark:border-neutral-700 text-neutral-800 dark:text-neutral-100'
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </a>
                      </div>
                    ))}


                  </div>
                  <a
                    onClick={() => setFormData(prevFormData => ({ ...prevFormData, images: [] }))}
                    className='flex cursor-pointer mb-5 justify-start items-center gap-2 px-2 rounded-md border text-[12px] border-neutral-200 bg-neutral-50 w-fit mx-auto dark:bg-neutral-900 dark:border-neutral-700 text-neutral-800 dark:text-neutral-100'
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
                      <path d="M3 6h18" />
                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                      <line x1="10" x2="10" y1="11" y2="17" />
                      <line x1="14" x2="14" y1="11" y2="17" />
                    </svg>
                    Clear Images
                  </a>
                </>
              )}


              <div className="grid gap-4 mb-6">
                <div className="grid md:grid-cols-2 gap-4">
                  <SelectVariant label="Website" options={websites} name="website_id" disabled={id ? true : false} handleChange={handleChange} defaultV={id ? formData.website?.id : formData.website_id} />
                  <SelectVariant label="Tag" options={TagsList} name="tag_id" disabled={id || !formData.website_id ? true : false} handleChange={handleChange} defaultV={id ? formData.tag?.id : formData.tag_id} />
                  <SelectVariant label="Region" options={regions} name="region_id" disabled={id || !formData.website_id ? true : false} handleChange={handleChange} defaultV={id ? formData.region?.id : formData.region_id} />
                  <SelectVariant label="City" options={CitiesList} name="city_id" disabled={id || !formData.website_id || !formData.region_id ? true : false} handleChange={handleChange} defaultV={id ? formData.city?.id : formData.city_id} />

                </div>
                <Input type='text' label="Title" name="title" handleChange={handleChange} defaultV={formData.title || ''} />
                <Input type='text' disabled={false} label="Slug" name="slug" handleChange={handleChange} defaultV={formData.slug || null} />

                <textarea
                  placeholder='Description...'
                  rows={4}
                  name="description"
                  onChange={handleChange}
                  value={formData?.description || ''} // Use value instead of defaultValue for controlled textarea
                  className='peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600'
                ></textarea>

                <div className="grid md:grid-cols-2 gap-4">
                  <Input type='text' label="Age" name="age" handleChange={handleChange} defaultV={formData.age || ''} />
                  <Input type='text' label="Snapchat" name="snapchat" handleChange={handleChange} defaultV={formData.snapchat || ''} />
                  <Input type='text' label="WhatsApp" name="whatsapp" handleChange={handleChange} defaultV={formData.whatsapp || ''} />

                  <Input type='text' label="Street" name="street" handleChange={handleChange} defaultV={formData.street || ''} />
                  <Select
                    options={formattedOptions(optionsWomen)}
                    name="type_of_woman"
                    onChange={options => handleChange({ target: { name: 'type_of_woman', value: options || [] } })}
                    isMulti
                    styles={customStyles} // Apply the custom styles
                    placeholder="Select type of women..."
                    value={formattedOptions(optionsWomen).filter(option => formData.type_of_woman?.includes(option.value))} // Multi-select value

                  />
                  <Select
                    options={statusOptions}
                    name="website_id"
                    onChange={option => handleChange({ target: { name: 'status', value: option ? option.value : '' } })}
                    disabled={id ? true : false}
                    styles={customStyles} // Apply the custom styles
                    placeholder="Select Status..."
                    value={statusOptions.find(opt => opt.value === formData.status)} // Single-select value

                  />

                </div>

                <div className='flex flex-col justify-center items-start gap-2'>
                  <label className='font-[700] text-[16px]'>Schedule</label>
                  <input
                    type="datetime-local"
                    className="peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600"
                    name="schedule_at"
                    onChange={(e) => handleChange(e)}
                    defaultValue={formData.schedule_at ? formData.schedule_at.substring(0, 16) : null} // Format to 'YYYY-MM-DDTHH:MM'
                  />
                </div>
              </div>

              <div className='flex justify-end items-center gap-4 mt-24'>
                <button type="submit" className='px-4 py-2 text-white bg-orange-600 dark:bg-orange-400 rounded-md'>Save</button>
                <Link to='/profiles' className='px-4 py-2 text-white bg-neutral-600 rounded-md'>Cancel</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default FormProfiles;
