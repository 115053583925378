import React, { useState } from 'react'
import axiosInstance from '../../utils/axios/axiosConfig';


function ForgetPassChange({ email, code, setChangePasswordModal }) {
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordToggleConfirm, setPasswordToggleConfirm] = useState('')
    const [passwordToggle, setPasswordToggle] = useState('')
    const [loading, setLoding] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const handleChangeFrogetPassword = async (e) => {
        e.preventDefault();
        try {
            if (!password || !passwordConfirm) {
                setErrorMessage('Both feilds must be filled !')
            }
            else if (password !== passwordConfirm) {
                setErrorMessage('Confirm Password is Incorrect !')
            } else {
                setLoding(true)
                const response = await axiosInstance.post('/auth/change-password-forget', { email, code, NewPassword: password });
                const responseData = response.data
                if (responseData && responseData.data && responseData.data.success) {
                    setChangePasswordModal(false)
                }
                setLoding(false)
            }

        } catch (error) {
            setErrorMessage(error.response.data.message)
            setLoding(false)
            console.error('Login failed:', error.response.data);
        }
    };


    return (
        <div className="relative z-10 transition-all duration-500" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="w-fit px-9 py-3 flex flex-col bg-white border shadow-sm rounded-2xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                        <div className='flex justify-center items-center flex-col items-start py-4'>
                            <h3 className="mb-3 text-3xl  font-bold text-dark-neutral-800 dark:text-gray-300">Change Password</h3>
                            <p className="mb-4 text-gray-700 dark:text-gray-400">Enter your new password and confirm it</p>
                        </div>
                        {errorMessage &&
                            <div className='flex mb-5 justify-start items-center gap-3 px-4 py-4 bg-orange-100 border border-orange-300 rounded-lg'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide  text-orange-800 lucide-circle-alert"><circle cx="12" cy="12" r="10" /><line x1="12" x2="12" y1="8" y2="12" /><line x1="12" x2="12.01" y1="16" y2="16" /></svg>
                                <p className='text-orange-800 font-[500] text-[13px] '>{errorMessage}</p>
                            </div>
                        }
                        <div className="relative flex flex-col">
                            <label htmlFor="password" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">New Password*</label>
                            <input id="password" type={`${passwordToggle ? 'text' : 'password'}`} onChange={(e) => setPassword(e.target.value)} placeholder="Enter a password" className="flex items-center  w-full px-5 py-4 mb-5 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                            {!passwordToggle ?
                                <svg onClick={() => setPasswordToggle(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%] ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                :
                                <svg onClick={() => setPasswordToggle(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            }
                        </div>
                        <div className="relative flex flex-col">
                            <label htmlFor="password" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Confirm Password*</label>
                            <input id="password" type={`${passwordToggleConfirm ? 'text' : 'password'}`} onChange={(e) => setPasswordConfirm(e.target.value)} placeholder="Enter a password" className="flex items-center  w-full px-5 py-4 mb-5 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                            {!passwordToggleConfirm ?
                                <svg onClick={() => setPasswordToggleConfirm(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                :
                                <svg onClick={() => setPasswordToggleConfirm(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            }
                        </div>
                        <div className="flex justify-center w-full items-center  ">

                            <button onClick={(e) => { handleChangeFrogetPassword(e) }} className="w-full px-6 py-5 mb-5 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-lg hover:bg-orange-blue-600 focus:ring-4 focus:ring-orange-blue-100 bg-blue-600 dark:bg-blue-900">{
                                !loading ? 'Validate'
                                    : (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                        <span className="sr-only">Loading...</span>
                                    </div>)}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassChange