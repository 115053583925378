import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';


function FormRoles() {
    const [sectionsPermissions, setSectionsPermissions] = useState([])
    const [roleInfo, setRoleInfo] = useState({ name: null, description: null })
    const [loading, setLoding] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate();


    const { id } = useParams()




    useEffect(() => {
        if (id) {
            getRoleToUpdate()
        } else {
            InitialiseSection()

        }
    }, [])


    const InitialiseSection = () => {
        setRoleInfo({ name: null, description: null })
        setSectionsPermissions([
            {
                section: "users",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "roles",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "websites",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "profiles",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "tags",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "regions",
                access: { create: false, read: false, update: false, delete: false },
            },
      
            {
                section: "blogs",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "cities",
                access: { create: false, read: false, update: false, delete: false },
            },
           {
                section: "leads",
                access: { create: false, read: false, update: false, delete: false },
            },
            {
                section: "settings",
                access: { create: false, read: false, update: false, delete: false },
            }


        ])
    }
    const InitialiseUpdateSection = (role) => {
        setRoleInfo({ name: role.name, description: role.description })
        setSectionsPermissions(role.permissions)
    }

    const handleCheckboxChange = (section, permission) => (e) => {
        setSectionsPermissions((prevPermissions) =>
            prevPermissions.map((perm) =>
                perm.section === section
                    ? { ...perm, access: { ...perm.access, [permission]: e.target.checked } }
                    : perm
            )
        );

    };

    const getRoleToUpdate = async () => {
        try {
            setErrorMessage('')
            setLoding(true)
            const response = await axiosInstance.get(`/role/get/${id}`);
            const responseData = response.data
            if (responseData) {
                setErrorMessage('')
                InitialiseUpdateSection(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            setErrorMessage(error.response.data.message)
            setLoding(false)
            console.error('Role creation failed:', error.response.data);
        }
    }


    const save = async (e) => {
        e.preventDefault();
        try {
            setErrorMessage('')
            setLoding(true)
            let response = null
            if (id) {
                response = await axiosInstance.put(`/role/update/${id}`, { name: roleInfo.name, description: roleInfo.description, permissions: sectionsPermissions });
            } else {
                response = await axiosInstance.post('/role/create', { name: roleInfo.name, description: roleInfo.description, permissions: sectionsPermissions });
            }
            const responseData = response.data
            if (responseData) {
                setErrorMessage('')
                if (id) {
                    getRoleToUpdate();
                    toast.success(responseData.message);
                } else {
                    InitialiseSection()
                    navigate(-1)
                    toast.success(responseData.message);

                }
            }
            setLoding(false)
        } catch (error) {
            setErrorMessage(error.response.data.message)
            toast.error(error.response.data.message);

            setLoding(false)
            console.error('Role creation failed:', error.response.data);
        }
    };






    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }} className=' w-full h-full p-5 '>
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                        <div className="border rounded-lg divide-y divide-neutral-200 dark:border-neutral-800 dark:divide-neutral-700">
                            <div className="grid rounded-t-lg bg-white dark:bg-neutral-800 gap-4  w-full py-5 px-4">
                                <ol className="flex items-center whitespace-nowrap">
                                    <li className="inline-flex items-center">
                                        <Link to='/Roles' className="flex items-center text-sm text-gray-500 hover:text-orange-600 focus:outline-none focus:text-orange-600 dark:text-gray-500 dark:hover:text-orange-500 dark:focus:text-orange-500" href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole"><circle cx="12" cy="16" r="1" /><rect x="3" y="10" width="18" height="12" rx="2" /><path d="M7 10V7a5 5 0 0 1 10 0v3" /></svg>
                                            Roles
                                        </Link>
                                        <svg className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </li>

                                    <li className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200" aria-current="page">
                                        {id ? 'Update' : 'New'} role
                                    </li>
                                </ol>
                                <div className="sm:flex items-center sm:justify-between ">
                                    <div>
                                        <div className="flex items-center gap-x-3">
                                            <h2 className="text-xl font-semibold text-gray-800 dark:text-white"><span>{id ? 'Update' : 'New'}</span> Role</h2>
                                        </div>
                                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">Here you will be able to create role by entring the name , description and the permissions of that role .</p>
                                    </div>
                                    <div className="flex items-center  gap-x-3">

                                        <button onClick={(e) => save(e)} className="flex items-center justify-center w-1/2 px-5 py-2 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            {
                                                !loading ?
                                                    (<>

                                                        {id ?
                                                            <>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                <span>Update Role</span>
                                                            </> :

                                                            <>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                                <span>Save Role</span>
                                                            </>

                                                        }
                                                    </>
                                                    )
                                                    : (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)}


                                        </button>
                                    </div>
                                </div>

                                <div className='flex md:flex-row flex-col gap-4 justify-center items-center w-full '>
                                    <div className="relative w-full">
                                        <input type="text" onChange={(e) => { setRoleInfo({ ...roleInfo, name: e.target.value }) }} id="hs-floating-input-passowrd-value" className="peer border bg-neutral-50 border-neutral-200  outline-none p-4 block w-full border-neutral-200 rounded-lg text-sm placeholder:text-transparent  disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 
                                        focus:pt-6
                                        focus:pb-2
                                        [&:not(:placeholder-shown)]:pt-6
                                        [&:not(:placeholder-shown)]:pb-2
                                        autofill:pt-6
                                        autofill:pb-2" placeholder="Enter role name" defaultValue={roleInfo.name} />
                                        <label htmlFor="hs-floating-input-passowrd-value" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                        peer-focus:scale-90
                                        peer-focus:translate-x-0.5
                                        peer-focus:-translate-y-1.5
                                        peer-focus:text-gray-500 dark:peer-focus:text-gray-500
                                        peer-[:not(:placeholder-shown)]:scale-90
                                        peer-[:not(:placeholder-shown)]:translate-x-0.5
                                        peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                        peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-gray-500 dark:text-gray-500">Name</label>
                                    </div>
                                    <div className="relative w-full">
                                        <input type="text" onChange={(e) => { setRoleInfo({ ...roleInfo, description: e.target.value }) }} id="hs-floating-input-passowrd-value" className="peer border bg-neutral-50 border-neutral-200  outline-none p-4 block w-full border-neutral-200 rounded-lg text-sm placeholder:text-transparent  disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 
                                        focus:pt-6
                                        focus:pb-2
                                        [&:not(:placeholder-shown)]:pt-6
                                        [&:not(:placeholder-shown)]:pb-2
                                        autofill:pt-6
                                        autofill:pb-2" placeholder="Enter role description" defaultValue={roleInfo.description} />
                                        <label htmlFor="hs-floating-input-passowrd-value" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                        peer-focus:scale-90
                                        peer-focus:translate-x-0.5
                                        peer-focus:-translate-y-1.5
                                        peer-focus:text-gray-500 dark:peer-focus:text-gray-500
                                        peer-[:not(:placeholder-shown)]:scale-90
                                        peer-[:not(:placeholder-shown)]:translate-x-0.5
                                        peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                        peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-gray-500 dark:text-gray-500">Description</label>
                                    </div>

                                </div>
                            </div>
                            <div className="overflow-hidden rounded-b-lg">
                                <table className="min-w-full divide-y divide-neutral-200 bg-white dark:bg-neutral-800 dark:divide-neutral-700">
                                    <thead className="bg-neutral-50 dark:bg-neutral-900">
                                        <tr className='h-14'>

                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Section Name</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Read</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Create</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Update</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Delete</th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                                        {sectionsPermissions.map((item) => (
                                            <tr key={item.section} className="h-14">
                                                <td className="px-6 py-3 text-start text-xs font-medium text-black dark:text-gray-200 uppercase ">
                                                    {item.section}
                                                </td>
                                                {['read', 'create', 'update', 'delete'].map((permission) => (
                                                    <td key={permission} className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">
                                                        <div className="inline-flex items-center">
                                                            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={`checkbox-${item.section}-${permission}`}>
                                                                <input
                                                                    checked={item.access[permission]}
                                                                    onChange={handleCheckboxChange(item.section, permission)}
                                                                    type="checkbox"
                                                                    className="before:content[''] peer relative bg-neutral-50 dark:bg-neutral-900 h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-neutral-200  dark:border-neutral-700  transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-orange-neutral-500 before:opacity-0 before:transition-opacity dark:checked:border-neutral-100 checked:border-neutral-900 dark:checked:bg-neutral-100 checked:bg-neutral-900 checked:before:bg-neutral-900 hover:before:opacity-10"
                                                                    id={`checkbox-${item.section}-${permission}`}
                                                                />
                                                                <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth={1}>
                                                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                                                    </svg>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </motion.div >
    )
}

export default FormRoles